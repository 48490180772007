// Imported to app to overide styles.
// Note variables are overriden by the antd plugin in craco config

@import './variables.less';

// custom vars
@drop-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);

// ************************************* //
// Fonts
// ************************************* //
@font-face {
  font-family: 'Hellix';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Hellix-Regular';
  src: local('Hellix-Regular'),
    url(./fonts/WOFF/Hellix-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Hellix-Medium';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Hellix';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-Bold.woff) format('woff');
  font-weight: 900;
}

@font-face {
  font-family: 'Hellix-Bold';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-Bold.woff) format('woff');
}

@font-face {
  font-family: 'Hellix';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-SemiBold.woff) format('woff');
  font-weight: 600;
}

@font-face {
  font-family: 'Hellix-SemiBold';
  src: local('Hellix-SemiBold'),
    url(./fonts/WOFF/Hellix-SemiBold.woff) format('woff');
}

@font-face {
  font-family: 'Hellix';
  src: local('Hellix'), url(./fonts/WOFF/Hellix-Thin.woff) format('woff');
  font-weight: 100;
}

@font-face {
  font-family: 'Hellix-Thin';
  src: local('Hellix-Thin'), url(./fonts/WOFF/Hellix-Thin.woff) format('woff');
}

// ************************************* //
// Global
// ************************************* //
h1 {
  margin-bottom: 9px;
  font-weight: 600;
}

h2 {
  font-size: 1.429rem;
  margin-bottom: 9px;
  line-height: normal;
  font-weight: 600;
}

h3 {
  font-size: 1.125rem;
  font-weight: 600;
}

h4,
h4 a,
h4 a:hover {
  color: @color-grape;
}

body a {
  color: @color-pickle;
}

body a:hover {
  color: @color-green;
}

.ant-input {
  padding: 20px;
}

.ant-legacy-form-explain {
  padding-top: 8px;
}

.app-wrapper {
  padding: 20px;

  @media screen and (max-width: @screen-xs) {
    padding: 20px 0;
  }
}

.active {
  color: @primary-color !important;
}

.inactive-row {
  opacity: 0.6;
  background: @color-tofu;
}

.line-through {
  text-decoration: line-through;
}

body {
  background: @color-tofu;
}

// ************************************* //
// Helpers
// ************************************* //

.w-100 {
  width: 100%;
}

.fullscreen {
  min-height: 100vh;
}

.absolute {
  position: absolute;
}

.top {
  top: 0;
}

.right {
  right: 0;
}

code {
  white-space: normal;
  word-wrap: break-word;
  margin-bottom: 0;
  background-color: fade(@primary-color, 40%);
}

.text-center {
  text-align: center;
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: @screen-xs) {
  .xs-hide {
    display: none;
  }
}

@media screen and (max-width: @screen-sm) {
  .sm-hide {
    display: none;
  }
}

@media screen and (min-width: @screen-sm) {
  .sm-only {
    display: none !important;
  }
}

.hide {
  display: none;
}

// ************************************* //
// Row
// ************************************* //
//
.ant-row {
  & + & {
    margin-top: 16px;
  }
}

// ************************************* //
// Card
// ************************************* //
//

body .ant-card {
  transition: height 1s @ease-in-out;
  background-color: #ffffff;
  box-shadow: @drop-shadow;
  border-radius: @border-radius-base;
  border: 0;
  margin-bottom: 20px;
}

.ant-card-dark {
  color: #ffffff;
  background: @color-grape;

  .ant-card-head-wrapper {
    color: #ffffff;
    border-bottom: none;
  }

  .anticon {
    color: #ffffff;
  }
}

.ant-card-head-wrapper {
  padding: 0;
}

.ant-collapse-header {
  color: #9b9b9b;
}

.ant-collapse-content {
  border-top: 0;
}

.ant-collapse-content-box {
  padding-top: 0;
}

.ant-card-head {
  padding: 0 25px;
}

.ant-card-body {
  padding: 0 25px 18px 25px;
}

.ant-card-head {
  border-bottom: 0;
}

.ant-card-actions {
  border-radius: 0 0 @border-radius-base @border-radius-base;
}

.ant-card-head-title {
  font-weight: 600;
  color: @color-grape;
}

// Make sure adjacent cards fill the height of their taller neighbour
// in grid layouts
.fill-height {
  display: flex;

  & > .ant-col {
    display: flex;
    justify-items: stretch;

    .ant-card {
      flex-grow: 1;
    }
  }
}

.flex-col {
  flex-direction: column;
}

// ************************************* //
// Button
// ************************************* //

.ant-btn {
  box-shadow: @drop-shadow;
}

body .ant-btn-primary {
  background: darken(@color-green, 5);
  border-color: darken(@color-green, 6);
}

// ************************************* //
// Menu
// ************************************* //

// Strong specificity to override
.ant-menu-item {
  user-select: none;
}

.ant-menu.ant-menu-light {
  .ant-menu-submenu:hover {
    .ant-menu-submenu-title,
    .ant-menu-submenu-title > .ant-menu-submenu-arrow {
      color: @link-color;
    }
  }

  .ant-menu-item:hover {
    color: @link-color;
  }

  .ant-menu-item-selected,
  .ant-menu-submenu-selected {
    color: @link-color;
  }

  .ant-menu-item-active,
  .ant-menu-submenu-active {
    color: @link-color;
    background: fade(@color-green, 15%);
    font-weight: bold;
  }

  .ant-menu-item-selected::after {
    border-color: @color-green;
  }
}

// ************************************* //
// Scheme menu
// ************************************* //

.ant-menu.scheme-menu {
  // To display dropdown over content in mobile nav
  z-index: 1;

  .ant-menu-submenu > .ant-menu-submenu-title {
    padding: 0;
    margin: 0;
    height: auto;
    background: @color-green;
  }

  // Pad only menu items on mobile nav, as inlineIndent is set to 0
  // so the title doesn't get padded too much!
  .ant-menu-inline .ant-menu-item-icon {
    padding-left: 24px;
  }
}

.scheme-menu-title {
  padding: 0;
  margin: 0;
  height: auto;
  width: 200px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 8px 20px;

  white-space: wrap;
  user-select: none;

  background: @color-green;
  transition: background 200ms ease-in-out;
  color: @color-grape;

  // Deeper padding on non mobile varirant, which is in the sider
  .ant-layout-sider & {
    padding: 25px 20px;
    width: 200px;
  }

  .ant-layout-sider .ant-menu-submenu:hover & {
    background: darken(@color-green, 2);
    color: @color-grape;
  }

  &--icon {
    font-size: 25px;

    .ant-layout-sider-collapsed & {
      font-size: 30px;
    }
  }

  &--text {
    flex: 1;
    font-size: 18px;
    line-height: 1.2;
    font-weight: bold;
    padding-bottom: 4px;
    padding-right: 10px;
  }
}

// ************************************* //
// Mobile header
// ************************************* //
.ant-layout-header.mobile-header {
  display: flex;
  justify-content: space-between;
  padding: 0;

  background: #fff;
  color: @color-grape;
}

.mobile-nav {
  z-index: 1;

  &.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 100%;
    line-height: unset;
    margin: 0;
  }

  &.ant-menu-submenu > .ant-menu-title-content {
    color: @color-grape;
  }
}

// ************************************* //
// Map
// ************************************* //
.mapboxgl-marker {
  transition: all 0.1s @ease-in-out;
}

body .mapboxgl-ctrl-bottom-right {
  // Override position absolute from mapbox, so it stays still if expanding nav on a short screen makes it scroll
  position: fixed;
}

// only do this for the big map
.minimap-wrapper .mapboxgl-ctrl-bottom-right {
  position: absolute;
}

// We think that some global AntD styling might be interfering with Mapbox's "Info" icon
// So doing a little CSS overriding here
.mapboxgl-ctrl-attrib.mapboxgl-compact {
  box-sizing: initial;
}

.map-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: #fff;
  }
}

.map-tooltip {
  pointer-events: none;
  position: absolute;
  z-index: 9;
  font-size: 12px;
  padding: 8px;
  background: #000;
  color: #fff;
  min-width: 160px;
}

.map-filter-card {
  @media screen and (max-width: (@screen-md - 1px)) {
    display: none;
  }
}

.back {
  z-index: 1;
  position: fixed;
  top: 20px;
  left: 20px;
  cursor: pointer;
  white-space: nowrap;
}

.minimap-wrapper {
  overflow: hidden;
  max-height: 620px;
  height: 100cqh;
  border-radius: 0 0 @border-radius-base @border-radius-base;
  position: relative;

  @media screen and (max-width: @screen-md) {
    height: 60vh;
  }
}

.minimap-wrapper-module {
  .minimap-wrapper;
  border-radius: @border-radius-base @border-radius-base @border-radius-base
    @border-radius-base;
  max-height: 800px;
}

.grid-legend {
  position: absolute;
  width: 280px;
  bottom: 0;
  top: 0;
  overflow: scroll;
  padding: 20px 10px 20px 20px;
}

// ************************************* //
// Table
// ************************************* //
.ant-table {
  background: #fff;
  border: 2px solid #f7f7f7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  .ant-table-thead > tr > th {
    background-color: #f7f7f7;
    font-weight: 600;
    padding: 16px 8px;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
    border-bottom: 2px solid #f7f7f7;
  }

  .ant-table-tbody > tr > td > a {
    color: @color-deepPurple;
    font-family: 'Hellix-SemiBold';
    font-size: 16px;
  }
}

.ant-table-row {
  cursor: pointer;
}

.ant-table .ant-table-tbody tr:hover td {
  background-color: #f7f7f7 !important;
}

.ant-table--clean {
  .ant-table-small,
  .ant-table-thead > tr > th {
    background: none;
    border: none !important;
  }

  .ant-table-tbody > tr > td {
    border: none;
  }
}

.ant-progress-inner {
  vertical-align: bottom;
}

.ant-progress-bg {
  background-color: @primary-color;
}

.ant-progress-status-active .ant-progress-inner {
  animation: increase 1.5s infinite;
}

.ant-table-pagination.ant-pagination {
  background: #f7f7f7;
  padding: 16px;
  margin: 0;
  float: none;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  justify-content: flex-end;

  .ant-pagination-item,
  a {
    border: none;
    background: unset;
  }

  .ant-pagination-item.ant-pagination-item-active {
    background-color: #ffffff;
  }

  .ant-pagination-item.ant-pagination-item-active > a {
    color: @color-grape;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover,
  .ant-pagination-jump-next:hover,
  .ant-pagination-jump-prev:hover {
    background-color: #ffffff;
  }

  .ant-pagination-item:focus,
  .ant-pagination-item:hover > a,
  .ant-pagination-next:hover > a,
  .ant-pagination-prev:hover > a {
    color: @color-grape;
  }

  .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon {
    color: @color-grape;
  }
}

.ant-table .ant-table-tbody > tr > td > a.ant-dropdown-link {
  font-size: 18px;
}

.basis-table {
  overflow-x: hidden;
}

.inactive-row {
  color: #999;
  background: #fcfcfc;

  strong {
    font-weight: normal;
  }
}

.subdued-row {
  color: #999;
  background: #fcfcfc;
}

@keyframes increase {
  from {
    left: -5%;
    width: 20%;
  }

  to {
    left: 105%;
    width: 100%;
  }
}

// ************************************* //
// Scheme grid
// ************************************* //
@scheme-name-bg: #eeeeee;
@scheme-select-bg: @primary-color;
@scheme-configure-bg: @layout-header-background;

.scheme-grid {
  div[class^='ant-col-'] {
    height: 100%;
  }
}

.scheme-grid-row {
  cursor: pointer;
  line-height: 0;
  height: 50px;
  margin-bottom: 6px;

  .ant-avatar {
    box-shadow: none;
  }

  &.active {
    border-left: 4px solid @primary-color;
    margin-bottom: 58px;
  }

  .anticon {
    font-size: 21px;
  }
}

.scheme-grid-col {
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
}

.scheme-grid-col--name {
  background-color: @scheme-name-bg;

  .active {
    > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
}

.scheme-grid-col--avatar {
  background-color: @scheme-name-bg;

  .ant-avatar {
    margin: 0 10px;
  }
}

.scheme-grid-col--select {
  background-color: @scheme-select-bg;
  color: #fff;
  justify-content: center;
  max-width: 70px;

  &:hover {
    background-color: shade(@scheme-select-bg, 15%);
  }
}

.scheme-grid-col--configure {
  background-color: @scheme-configure-bg;
  color: #fff;
  justify-content: center;
  max-width: 70px;

  &:hover {
    background-color: shade(@scheme-configure-bg, 15%);
  }
}

.scheme-name-active-meta {
  font-size: 9px;
  margin-bottom: 15px;
}

.sticky-menu {
  // Dont scroll unless content is fully scrolled
  position: sticky;
  top: 0;
  // Make sure footer will be at the bottom
  min-height: 100vh;
}

// ************************************* //
// Footer
// ************************************* //
.footer {
  // Stick to the bottom when menu isn't long enough to push it
  position: sticky;
  top: calc(100vh - 105px);

  // Gap for when menu does touch it
  margin-top: 40px;
  width: 200px;
  padding-left: 20px;

  p {
    font-size: 10px;
    color: @color-grey;
  }

  &--microcopy {
    margin-bottom: 5px;
  }

  &--version {
    margin-top: 15px;
  }

  img {
    width: 80px;
  }

  .ant-layout-sider-collapsed & {
    width: 55px;
    padding-left: 12px;
    top: calc(100vh - 80px);

    p {
      display: none;
    }

    img {
      width: 100%;
    }
  }
}

// ************************************* //
// Stripe form
// ************************************* //

.CardDetailsForm {
  display: flex;
  justify-content: space-between;
  padding-left: 14px;
  border: 1px solid #d9d9d9;
  border-radius: @border-radius-base;
}

.CardDetailsForm.CardDetailsForm--focused {
  border-color: @primary-color;
}

.CardDetailsForm.CardDetailsForm--hasError {
  border-color: #ff5058;
}

.CardDetailsForm-fieldWrapper {
  padding: 18px 0 18px;
  flex: 1;
}

.CardDetailsForm button {
  margin-left: 8px;
  border-radius: 0 7px 7px 0;
  height: auto;
}

// ************************************* //
// Payment cards
// ************************************* //

.PaymentCardDisplay,
.PaymentCardDisplay-left {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PaymentCardDisplay span {
  font-weight: 600;
}

// ************************************* //
// EndJourneyConfirmation
// ************************************* //

.EndJourneyConfirmation-text {
  transition: all 0.3s;
}

.EndJourneyConfirmation-text-visible {
  transition: all 0.4s;
  transition-delay: 0.2s;
}

.EndJourneyConfirmation-button {
  transition: width 0.2s;
}

// ************************************* //
// Inputs
// ************************************* //

.InputNumber-input-container {
  background-color: rgb(241, 241, 241);
  border-color: rgb(241, 241, 241);
  border-radius: 8px;
}

.InputNumber-input {
  font-size: 22px;
  font-family: 'Hellix-SemiBold';
  height: 48px;
  width: 100%;
  color: @color-deepPurple;
  padding: 6px 8px 8px 8px;
  background-color: rgba(0, 0, 0, 0);
  border: none;
}

.InputNumber-input .ant-input-number:focus {
  color: @color-deepPurple;
  border: none;
}

/**
 * Override to hide the select all checkbox on tables.
 * the rowSelection.hideSelectAll property isn't supported until v4.3.0
 */
.hide-select-all {
  .hide-select .ant-checkbox-wrapper,
  .ant-table-column-title .ant-checkbox-wrapper {
    display: none;
  }
}

@color-tofu: #f7f7f7;@color-grape: #52525c;@color-deepPurple: #3c3c43;@color-pear: #01a78f;@color-pickle: #009b85;@color-lake: #1a98ff;@color-green: #00e3c2;@color-grey: #888;@primary-color: @color-green;@primary-1: fade(@color-green, 15%);@info-color: @color-pickle;@link-color: @color-pickle;@font-family: Hellix, Helvetica, Arial, sans-serif;@layout-header-background: @color-grape;@border-radius-base: 7px;@card-padding-base: 30px;@menu-height: 57px;@menu-width: 200px;@submenu-item-height: 67px;@menu-inline-toplevel-item-height: 48px;@menu-item-height: 48px;@menu-item-active-bg: none;@menu-dark-item-active-bg: none;@input-height-base: 32px;@input-height-lg: 44px;@input-height-sm: 24px;